var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('ABreadcrumb',{attrs:{"items":_vm.breadcrumbItems}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.alertError.length > 0)?_c('v-alert',{staticClass:"text-pre-wrap",attrs:{"dense":"","border":"left","type":"warning"}},_vm._l((_vm.alertError),function(error,index){return _c('span',{key:index,staticClass:"d-block"},[_vm._v(_vm._s(error))])}),0):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.claimSubmitDialog),callback:function ($$v) {_vm.claimSubmitDialog=$$v},expression:"claimSubmitDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.hrdcData.HRDC_BLUE_THEME_COLOR,"dark":""}},[_vm._v(" Claim Submitted to HRDC ")]),_c('div',{staticClass:"pa-4"},[_vm._v(" Please confirm that you wish to Submit Claim to HRDC ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-light",attrs:{"color":"green lighten-1"},on:{"click":function($event){return _vm.modalClaimSubmit()}}},[_vm._v(" Submit ")]),_c('v-btn',{staticClass:"text-light",attrs:{"color":"red lighten-1"},on:{"click":function($event){_vm.claimSubmitDialog = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-card',[_c('div',[_c('v-toolbar',{attrs:{"color":"#063058"}},[_c('v-btn',{staticClass:"hidden-xs-only text-white",attrs:{"icon":""},on:{"click":function($event){return _vm.redirectBack()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),(!_vm.loadingDialog)?_c('v-toolbar-title',[_c('div',{staticClass:"d-flex align-center text-white"},[_vm._v(" "+_vm._s(_vm.model.add.name)+" ")])]):_vm._e(),_c('v-spacer'),(_vm.actionDownload)?_c('span',[_c('v-menu',{attrs:{"offset-y":"","rounded":'lg',"transition":"slide-y-transition","max-height":'300'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3546143073)},[_c('v-list',_vm._l((_vm.actions),function(action,index){return _c('v-list-item',{key:index,attrs:{"dense":""},on:{"click":function($event){return _vm.downloadAttachment(action)}}},[_c('v-list-item-title',[_vm._v(_vm._s(action))])],1)}),1)],1)],1):_vm._e(),(_vm.hrdcData.isHrdcAdminRoles && !_vm.btnDisabled)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"hidden-xs-only text-white",attrs:{"icon":"","loading":_vm.apiSubmit.isLoading},on:{"click":function($event){_vm.claimSubmitDialog = true}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document-arrow-right ")])],1)]}}],null,false,3518426466)},[_c('span',[_vm._v("Submit Claim")])])],1):_vm._e()],1)],1),_c('div',{staticClass:"w-50 pa-2"},[_c('v-card',{staticClass:"pa-2 ma-2",style:({ backgroundColor: _vm.hrdcData.HRDC_CARD_COLOR }),attrs:{"outlined":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","disabled":"","label":"Grant Approval No.","error-messages":_vm.errorField.grantApprovalNo},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.copyContent(_vm.formData.grantApprovalNo)}}},[_vm._v("mdi-content-copy")])]},proxy:true}]),model:{value:(_vm.formData.grantApprovalNo),callback:function ($$v) {_vm.$set(_vm.formData, "grantApprovalNo", $$v)},expression:"formData.grantApprovalNo"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","label":"Claim ID","error-messages":_vm.errorField.claimId},model:{value:(_vm.formData.claimId),callback:function ($$v) {_vm.$set(_vm.formData, "claimId", $$v)},expression:"formData.claimId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","label":"Claim Submit Date (YYYY-MM-DD)","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formData.claimSubmitDate),callback:function ($$v) {_vm.$set(_vm.formData, "claimSubmitDate", $$v)},expression:"formData.claimSubmitDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.formData.claimSubmitDate),callback:function ($$v) {_vm.$set(_vm.formData, "claimSubmitDate", $$v)},expression:"formData.claimSubmitDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)]),_c('HrdcLoader',{attrs:{"loadingDialog":_vm.loadingDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }